<template>
  <div class="ads">
    <a :href="href">
      <slot>
        <img :src="src" :alt="title" class="w-100"/>
      </slot>
    </a>
  </div>
</template>
<script>
export default {
  name: "AdsCMP",
  props: {
    title: {
      type: String,
      default: "تخفیف کود"
    },
    src: {
      type: String,
      default: "/img/sample-ads/4.jpg"
    },
    href: {
      type: String,
      default: "#"
    }
  }
}
</script>
<style>

</style>
